import React from "react";
import styled from "styled-components";
import {Row, Col} from "react-bootstrap";
import {Link} from "gatsby";
import {StaticImage} from "gatsby-plugin-image";

import {Title, Button, Section, Box, Text} from "../../components/Core";
import {device} from "../../utils";
import data from "../../../site/content/index.json";
import Container from "../../components/Layout/Container";

interface Props {
  buttons: {
    to: string;
    color: string;
    text: string;
    variant: string;
  }[];
  text: string;
  title: string;
  preTitle?: string;
  video?: string;
  image?: string;
  smallPreTitle?: string;
}

const ImageRight = ({buttons, text, title, preTitle, video, image, smallPreTitle}: Props) => (
  <>
    {/* <!-- Content section 1 --> */}
    <Section>
      <Container>
        <Row className="align-items-center">
          <Col md="12" lg="5" className="order-lg-3">
            <div className="position-relative pl-lg-5">
              <div className="pl-lg-4 text-center text-lg-right">
                {!video && !image && (
                  <div
                    style={{
                      height: 400,
                      width: 400,
                      borderRadius: "100%",
                      backgroundColor: "#075c8d",
                    }}
                  />
                )}
                {image && (
                  <img style={{
                    width: 500,
                  }} src={image}/>
                )}
                {video && (
                  <video
                    autoPlay
                    muted
                    loop
                    playsInline
                    width="500"
                    height="500"
                    style={{aspectRatio: "785 / 660"}}
                  >
                    <source data-src={video} src={video} type="video/mp4"/>
                  </video>
                )}
              </div>
            </div>
          </Col>
          {/*<Col lg="1" className="order-lg-2" />*/}
          <Col lg="6" className="order-lg-1 mt-5 mt-lg-0">
            <Box>
              <Title
                fontSize="42px !important"
                lineHeight="48px !important"
                textColor="#f44e41"
                marginBottom="0px !important"
              >
                {preTitle}
              </Title>
              <Title fontSize="42px !important" lineHeight="48px !important" marginBottom="10px !important">
                {title}
              </Title>
              <Title
                fontSize="28px !important"
                lineHeight="28px !important"
                textColor="#f44e41"
                marginTop="0px !important"
                marginBottom="20px !important"
              >
                {smallPreTitle}
              </Title>
              <Text mb={4}>{text}</Text>
              <Row style={{paddingLeft: 15}}>
                {buttons.map(({color, text, to, variant}, index) => (
                  <Link key={index} to={to}>
                    <Button mt={4} variant={variant} color={color} mr={3}>
                      {text}
                    </Button>
                  </Link>
                ))}
              </Row>
            </Box>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default ImageRight;
