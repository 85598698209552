import React from "react";
import data from "../../../site/content/index.json";
import ImageRight from "../../components/Content/ImageRight";

const Content1 = () => (
  <ImageRight
    buttons={data.content_3.buttons}
    text={data.content_3.text}
    title={data.content_3.title}
    smallPreTitle={"Connected Body Worn Camera solutions"}
    image="/assets/GC550-1.png"
  />
);

export default Content1;
