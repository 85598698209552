import React from "react";
import data from '../../../site/content/index.json'
import ImageLeft from "../../components/Content/ImageLeft";


const Content2 = () => (
  <ImageLeft
    buttons={data.content_2.buttons}
    text={data.content_2.text}
    smallPreTitle="Intelligent Two-way radio system."
    title={data.content_2.title}
    image="/assets/smart-radio-home.png"
  />
);

export default Content2;
