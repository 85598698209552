import React from "react";
import ImageRight from "../../components/Content/ImageRight";
import data from "../../../site/content/index.json";

const Content1 = () => (
  <ImageRight
    buttons={data.content_1.buttons}
    text={data.content_1.text}
    title={data.content_1.title}
    smallPreTitle="Crime reporting, simplified."
    video="/assets/alert-1.1-face-match.webm"
  />
);

export default Content1;
