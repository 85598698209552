import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "gatsby";

import { Title, Section, Box, Text, Button } from "../../components/Core";
import Container from "../../components/Layout/Container";

interface Props {
  buttons: {
    to: string;
    color: string;
    text: string;
    variant: string;
  }[];
  text: string;
  title: string;
  preTitle?: string;
  smallPreTitle?: string;
  video?: string;
  fallback?: string;
  image?: string;
}

const ImageLeft = ({
  buttons,
  text,
  title,
  preTitle,
  smallPreTitle,
  video,
  fallback,
  image
}: Props) => (
  <>
    {/* <!-- Content section 2 --> */}
    <Section className="position-relative">
      <Container>
        <Row className="align-items-center">
          <Col lg="6" md="9" sm="10" className="mb-5 mb-lg-0">
            <div
              className="position-relative"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {!video && !image && (
                <div
                  style={{
                    height: 400,
                    width: 400,
                    borderRadius: "100%",
                    backgroundColor: "#075c8d",
                  }}
                />
              )}
              {video && (
                <video
                  autoPlay
                  muted
                  loop
                  playsInline
                  width="785"
                  height="660"
                  style={{ aspectRatio: "785 / 660" }}
                >
                  <source data-src={video} src={video} type="video/mp4" />
                  <img src={fallback}></img>
                </video>
              )}
              {image && (
                <img style={{
                  width: 500,
                }} src={image} />
              )}
            </div>
          </Col>
          <Col lg="6">
            <div className="content-text pl-lg--50">
              <div className="section-title">
                <Title
                  fontSize="42px !important"
                  lineHeight="48px !important"
                  textColor="#f44e41"
                  marginBottom="0px !important"
                >
                  {preTitle}
                </Title>
                <Title fontSize="42px !important" lineHeight="48px !important" marginBottom="10px !important">
                  {title}
                </Title>
                <Title
                  fontSize="28px !important"
                  lineHeight="28px !important"
                  textColor="#f44e41"
                  marginTop="0px !important"
                  marginBottom="20px !important"
                >
                  {smallPreTitle}
                </Title>
                <Text mb={4}>{text}</Text>
              </div>
              {buttons.map(({ to, color, text, variant }, index) => (
                <Link key={index} to={to}>
                  <Button
                    mt={4}
                    variant={variant}
                    background-color={color}
                    mr={3}
                  >
                    {text}
                  </Button>
                </Link>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default ImageLeft;
